import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Styles from "../components/services-template.module.css"
import Upnext from "../components/upnext"
import ContentLayout from "../components/contentLayout"

const Intro = () => {
  return (
    <>
      <h1 className="fancy text-brand text-base uppercase tracking-wider font-bold">
        Our services
      </h1>
      <h2 className="my-10 mb-12 text-4xl font-bold leading-snug">
        We're on the frontline of design<span className="text-brand">.</span>
      </h2>

      <div className={Styles.grid}>
        <section>
          <h3 className="text-base uppercase tracking-wider font-bold mb-4">
            Digital
          </h3>

          <ul className={`mb-4 ${Styles.list}`}>
            <li>Websites</li>
            <li>Mobile apps (Android/iOS)</li>
            <li>Google compliant banners</li>
            <li>Ecommerce</li>
            <li>SEO</li>
          </ul>
        </section>

        <section>
          <h3 className="text-base uppercase tracking-wider font-bold mb-4">
            Graphic design
          </h3>

          <ul className={`mb-4 ${Styles.list}`}>
            <li>Branding</li>
            <li>Print Design</li>
            <li>Point of sale</li>
            <li>Large format</li>
            <li>Video production</li>
          </ul>
        </section>
        <section>
          <h3 className="text-base uppercase tracking-wider font-bold mb-4">
            Public relations
          </h3>

          <p className="text-sm">
            <AniLink
              className="underline"
              bg="#ef3f4a"
              cover
              direction="right"
              to={`contact-us`}
            >
              Contact us
            </AniLink>{" "}
            if you'd like to know more about how we can help with your public
            relations.
          </p>
        </section>
      </div>
    </>
  )
}

const Web = () => {
  return (
    <>
      <h2 className="fancy text-brand text-base uppercase tracking-wider font-bold">
        Web Design <span className="text-dark">+</span> Development
      </h2>
      <h2 className="my-10 mb-12 text-4xl font-bold leading-snug">
        We excel at digital experiences<span className="text-brand">.</span>
      </h2>
      <p>
        We approach every project with a blank slate. Instead skinning a
        template and calling it a day, we build truely unique websites from the
        ground up, finely tuned to meet the requirements of your brief and
        creative freedom to build exactly what you hope for. This also enables
        us to provide features that aren't dependent on off-the-shelf plugins
        that may or may not be supported by the creator as your business and
        website grows. We believe this sets us apart from many other studios who
        provide less for your investment. Working with the worlds leading
        Content Management Systems, we're committed to providing an easy-to-use
        user experience for both the end user and you.
      </p>
    </>
  )
}

const GraphicDesign = () => (
  <>
    <h2 className="fancy text-brand text-base uppercase tracking-wider font-bold">
      Graphic Design <span className="text-dark">+</span> Branding
    </h2>
    <h2 className="my-10 mb-12 text-4xl font-bold leading-snug">
      We craft authenic brands that people can trust
      <span className="text-brand">.</span>
    </h2>

    <p className="mb-6">
      We understand that a brand is so much more than a logo. A brand is a
      living entity, a voice. With years of experience in branding and public
      relations, we know how to present you to the people that matter, and look
      great while you're at it.
    </p>
  </>
)

const Services = ({ location }) => {
  return (
    <Layout
      location={location}
      header={`dark`}
      image={`https://images.unsplash.com/photo-1523726491678-bf852e717f6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80`}
    >
      <SEO title="Our Services" />
      <ContentLayout
        featureImage={`https://images.unsplash.com/photo-1509805225007-73e8ba4b5be8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80`}
      >
        <Intro />
      </ContentLayout>
      <ContentLayout
        featureImage={`https://images.unsplash.com/photo-1493307100940-ac5f30709573?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80`}
        right={true}
      >
        <Web />
      </ContentLayout>
      <ContentLayout
        featureImage={`https://images.unsplash.com/photo-1561070791-36c11767b26a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80`}
      >
        <GraphicDesign />
      </ContentLayout>

      <Upnext to={"contact-us"} text={"Ready to start? Contact us"} />
    </Layout>
  )
}

export default Services
