import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Styles from "./upnext.module.css"

const Upnext = ({ text, to }) => {
  return (
    <AniLink
      className={`bg-offwhite text-brand cursor-pointer font-bold text-base md:text-xl py-10 md:p-16 text-whitemax-w-4xl px-10 md:px-16 mx-auto flex justify-end items-center ${Styles.chevHov}`}
      cover
      to={to}
      direction="left"
      bg="#161625"
    >
      <div className={`flex items-center`}>
        {text}
        <div className={Styles.chevron}></div>
      </div>
    </AniLink>
  )
}

export default Upnext
